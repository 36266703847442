const getComputedStyleForDropdown = (width, height) => ({
  display: 'block',
  position: 'absolute',
  zIndex: '9999',
  width: `${width}px`,
  maxHeight: height || 'auto',
  overflowY: 'auto',
  overflowX: 'hidden',
  overscrollBehavior: 'contain',
  inset: 'auto'
});

const applyInitialStyles = (contentElement, width, height) => {
  Object.assign(
    contentElement.style,
    getComputedStyleForDropdown(width, height)
  );
};

const positionDropdown = (contentElement, triggerElement) => {
  const triggerRect = triggerElement.getBoundingClientRect();
  const contentHeight = contentElement.offsetHeight;
  const availableBelow = window.innerHeight - triggerRect.bottom;
  if (availableBelow < contentHeight) {
    contentElement.style.top = 'auto';
    contentElement.style.bottom = `35px`;
  } else {
    contentElement.style.top = `${triggerElement.offsetHeight}px`;
    contentElement.style.bottom = 'auto';
  }
};

const handleContainerQueries = (contentElement, dropdownElement, width) => {
  const dropdownWidth = dropdownElement.offsetWidth;
  const cellWidth = width || dropdownWidth;
  const minWidth = 180;

  if (cellWidth < minWidth) {
    // eslint-disable-next-line no-magic-numbers
    const offsetPercentage = -((minWidth - cellWidth) / 2 / minWidth) * 100;
    contentElement.style.transform = `translateX(${offsetPercentage}%)`;
  } else {
    contentElement.style.transform = '';
  }
};

const closeAllDropdowns = (openDropdowns, except = null) => {
  openDropdowns.forEach((id) => {
    if (id !== except) {
      const dropdown = document.getElementById(id);
      if (dropdown) {
        const content = dropdown.querySelector('.dropdown-content');
        if (content) content.style.display = 'none';
      }
    }
  });
  if (except) {
    openDropdowns.clear();
    openDropdowns.add(except);
  } else {
    openDropdowns.clear();
  }
};

const toggleDropdown = (dropdownElement, triggerElement, openDropdowns) => {
  const dropdownId = dropdownElement.id;
  const contentElement = dropdownElement.querySelector('.dropdown-content');
  if (!contentElement) return;
  const width = dropdownElement.dataset.width || '180px';
  const height = dropdownElement.dataset.height || '224px';
  const searchable = dropdownElement.dataset.searchable === 'true';
  const isHidden =
    !contentElement.style.display || contentElement.style.display === 'none';
  const delay = 10;

  closeAllDropdowns(openDropdowns, isHidden ? dropdownId : null);

  if (isHidden) {
    applyInitialStyles(contentElement, width, height);
    openDropdowns.add(dropdownId);
    positionDropdown(contentElement, triggerElement);
    handleContainerQueries(contentElement, dropdownElement, width);

    contentElement.addEventListener('wheel', (e) => e.stopPropagation());

    if (searchable) {
      setTimeout(() => {
        const searchInput = contentElement.querySelector(
          '.dropdown-search-input'
        );
        if (searchInput) searchInput.focus();
      }, delay);
    }
  } else {
    contentElement.style.display = 'none';
    openDropdowns.delete(dropdownId);
  }
};

const createGlobalClickHandler = (openDropdowns) => (event) => {
  const trigger = event.target.closest('.custom-dropdown-trigger');
  if (trigger) {
    const dropdownElement = trigger.closest('.custom-dropdown');
    if (dropdownElement) {
      toggleDropdown(dropdownElement, trigger, openDropdowns);
      event.stopPropagation();
      event.preventDefault();
    }
    return;
  }
  if (!event.target.closest('.custom-dropdown')) {
    closeAllDropdowns(openDropdowns);
  }
};

const createGlobalInputHandler = () => (event) => {
  if (!event.target.matches('.dropdown-search-input')) return;
  const input = event.target;
  const dropdownElement = input.closest('.custom-dropdown');
  if (dropdownElement) {
    const searchTerm = input.value.toLowerCase();
    const searchItemSelector =
      dropdownElement.dataset.searchItemSelector || 'li, .dropdown-item';
    const items = dropdownElement.querySelectorAll(searchItemSelector);
    items.forEach((item) => {
      item.style.display = item.textContent.toLowerCase().includes(searchTerm)
        ? ''
        : 'none';
    });
    event.stopPropagation();
  }
};

export const initDropdownManager = () => {
  if (window.dropdownManager) return;
  const openDropdowns = new Set();

  const globalClickHandler = createGlobalClickHandler(openDropdowns);
  const globalInputHandler = createGlobalInputHandler();

  document.addEventListener('click', globalClickHandler, true);
  document.addEventListener('input', globalInputHandler);

  window.dropdownManager = {
    openDropdowns,
    closeAll: (except = null) => closeAllDropdowns(openDropdowns, except),
    globalClickHandler,
    globalInputHandler
  };
};

export const cleanupDropdownManager = () => {
  if (window.dropdownManager) {
    document.removeEventListener(
      'click',
      window.dropdownManager.globalClickHandler,
      true
    );
    document.removeEventListener(
      'input',
      window.dropdownManager.globalInputHandler
    );
    window.dropdownManager = null;
  }
};

export const generateRandomId = () => {
  const base = 36;
  const minLength = 2;
  const maxLength = 9;
  const randomString = Math.random().toString(base).slice(minLength, maxLength);
  return `dropdown-${randomString}`;
};
