import { getCompanyTextColor } from '../../../../utils';
import RemoveIcon from '../../../../assets/img/gantt/X.png';

export const renderRemoveBtn = (t) => `
  <div class="remove-companies-x">
    <img width="10" src="${RemoveIcon}" alt="Remove icon" />
  </div>
  <div class="remove-companies-text">${t('remove_subcontract_label')}</div>
`;

export const renderSubToSelect = (option, column, selectedSub) => {
  const { object } = option;
  const isSelected = selectedSub?.id === object?.id;
  return `
    <div class="item-gantt ${isSelected ? 'selected-option' : ''}">
      <div 
        class="company-border cut-text-and-dot"
        style="color: ${getCompanyTextColor(object?.color)}; background-color: ${object?.color}">
        <div class="text-company cut-text-and-dot">
          ${object?.name}
        </div>
      </div>
    </div>
  `;
};

export const renderSelectedOption = (selected, isOnlyReadElement, column) => {
  const object = selected;
  return `
    <div 
      class="item-gantt item-custom-selected-companies selected-option-container ${isOnlyReadElement ? 'read-only' : 'clickable'}">
      <div
        class="company-border selected-company-border"
        style="color: ${getCompanyTextColor(object?.color)}; background-color: ${object?.color}">
        <div class="text-company selected-text-company cut-text-and-dot" 
          style="padding-left: 5px; padding-right: 5px;"
        >
          ${object?.name}
        </div>
      </div>
    </div>
  `;
};
