import {
  renderRemoveBtn,
  renderSelectedOption,
  renderSubToSelect
} from './templates';
import { updateAsyncTaskGanttV2 } from '../../GanttVisualization.helper';

export const registerTaskCallbacks = (taskId, task, options) => {
  const {
    taskFromLookahead,
    column,
    gantt,
    callbackOnSelection,
    callbackOnClickCreate,
    callbackOnClickRemove
  } = options;

  window.subCallbacks = window.subCallbacks || {};
  window.subCallbacks[taskId] = {
    selection: (id) => {
      task.subcontractId = id;
      callbackOnSelection && callbackOnSelection(id);
      if (taskFromLookahead) {
        updateAsyncTaskGanttV2(taskFromLookahead, column.name, id, gantt);
      }
    },
    create: () => callbackOnClickCreate && callbackOnClickCreate(),
    remove: () => {
      task.subcontractId = null;
      gantt.updateTask(task.id);
      callbackOnClickRemove && callbackOnClickRemove();
    }
  };
};

export const prepareUIElements = (options) => {
  const { selectedObject, optionsList, isOnlyReadElement, t, column } = options;
  const selectedSub = selectedObject.subcontractId
    ? optionsList.find(
        (companies) =>
          String(companies.id) === String(selectedObject.subcontractId)
      )
    : null;

  const placeholderHtml = selectedSub
    ? renderSelectedOption(selectedSub, isOnlyReadElement, column)
    : `<div class="empty-selection">
         <div class="plus-icon-container"></div>
       </div>`;

  const optionsListHtml = optionsList
    .map(
      (option) => `
      <li class="dropdown-option" style="cursor: pointer; list-style: none;"
          data-action="subSelection" data-value="${option.id}-${option.name}">
        ${renderSubToSelect({ object: option }, column, selectedSub)}
      </li>
    `
    )
    .join('');

  const createBtnHtml = `
    <button type="button" class="create-option" data-action="subCreate">
      <div>+ ${t('user_config.new_subcontract')}</div>
    </button>
  `;

  const removeBtnHtml = selectedObject.subcontractId
    ? `
    <button type="button" class="remove-option" data-action="subRemove">
      ${renderRemoveBtn(t)}
    </button>
  `
    : '';

  return { placeholderHtml, optionsListHtml, createBtnHtml, removeBtnHtml };
};

const handleCompaniesAction = (action, target, taskId) => {
  const callbacks = window.subCallbacks && window.subCallbacks[taskId];
  if (!callbacks) return;

  const actionHandlers = {
    subSelection: () => {
      const value = target.getAttribute('data-value');
      const valueId = value ? value.split('-')[0] : null;
      callbacks.selection && callbacks.selection(valueId);
    },
    subCreate: () => {
      callbacks.create && callbacks.create();
    },
    subRemove: () => {
      callbacks.remove && callbacks.remove();
    }
  };

  const handler = actionHandlers[action];
  if (handler) {
    handler();
  }
};

export const setupGlobalHandlers = () => {
  window.subCallbacks = window.subCallbacks || {};
  if (window._companiesGlobalHandler) return;
  const companiesGlobalHandler = (event) => {
    const target = event.target.closest('[data-action]');
    if (!target) return;
    const action = target.getAttribute('data-action');
    const container = target.closest('[data-task-id]');
    if (!container) return;
    const taskId = container.getAttribute('data-task-id');
    handleCompaniesAction(action, target, taskId);
    event.stopPropagation();
  };
  document.addEventListener('click', companiesGlobalHandler);
  window._companiesGlobalHandler = companiesGlobalHandler;
};

export const cleanupCompaniesGlobalHandlers = () => {
  if (window._companiesGlobalHandler) {
    document.removeEventListener('click', window._companiesGlobalHandler);
    delete window._companiesGlobalHandler;
  }
};
