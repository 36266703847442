/** React stuff */
import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { debounce } from 'lodash';

/** Redux */
import { useSelector, useDispatch } from 'react-redux';
import { userActions } from '../../redux/actions/userActions';
import { timelineActions } from '../../redux/actions/lookaheadTimelineActions';

/** JSON with table distribution */
// import { tableMetadata } from './table.layout'

/** Function to configure the GANTT DHTMLX LIB */
import { ganttConfig } from './libs/gantt.config';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import useWeeklyplan from '../../hooks/useWeeklyplan';
import { useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/react';

/** utils */
import {
  calculatePonderators,
  calculateProgress,
  getRecursiveFromParentTask,
  getTask,
  saveForwardWeeklyData
} from '../../utils/lookahead-common';

/** import utilities from component helper */
import {
  renderModalRequestModification,
  renderModalCustomPonderator,
  updateTaskDragSave,
  findDeepGetTask,
  updateAsyncTaskGanttV2,
  defaultGanttFields,
  getReferences,
  updateTaskMassive
} from './GanttVisualization.helper';

import '../../assets/gantt/dhtmlxgantt.css';

/** Import elements from library Antd */
import { Spin, Icon, Modal, Row, Col } from 'antd';

/** import plugins */
import { configExportPlugin } from '../../assets/gantt/ext/dhtmlxgantt_export'; /** Export (PROJECT-PDF) API from dhtmlx */
import { transformHourToDays } from '../../views/ganttContainer/gantt/gantt.helper';

import ModalWeeklyContentImg from '../../assets/img/newlookahead/weekly/modal-content.png';
import { taskService } from '../../services';
import buildReactRender from './dependencies/RenderReactCol';
import useSubtradeUser from '../../hooks/useSubtradeUser';
import useCheckForwardWeekly from '../../hooks/useCheckForwardWeekly';

import { lookaheadActions } from '../../redux/actions/lookaheadActions';
import { ganttAPI as customProPlannerGanttRef } from '../../utils/customGanttPlugin';
import {
  getWeekNumberFromStartWeek,
  getYearFromThursday,
  openNotification
} from '../../utils';
import { AMPLITUDE_SERVICE } from '../../analytics/constants';
import { trackingEvent } from '../../analytics';
import { getBasicAmplitudEventProperties } from '../../analytics/utils';

import {
  save_single_task_lookahead,
  workerService
} from '../../services/serviceworker.service';
import { TimerManagerSingleton } from '../../utils/timerManager';
import { handleWeeklyRangeOnDataChange } from './libs/gantt.utils';
export const TestIds = {
  GANTT_VISUALIZATION_CONTAINER: 'GANTT_VISUALIZATION_CONTAINER'
};
function GanttVisualization({
  setGanttLoaded = () => {},
  gantt = customProPlannerGanttRef.gantt,
  data = {
    data: []
  },
  t = () => {},
  stateToExtract = {},
  actions = lookaheadActions,
  onDoubleClickTask = () => {},
  updateRender = () => {},
  tableConfig: tableMetadata,
  materials = [],
  specialties = [],
  machineries = [],
  isShowPreview = false,
  markersDates = {},
  handleAddConstraint = () => {},
  permission = {},
  subContracts = [],
  handleUnfit = () => {},
  groupBy = { criteria: '' },
  toSelectTags = [],
  toSelectSubs = [],
  setActivityResource = () => {},
  activities = [],
  updateGanttVisualizationState = () => {},
  toSelectResponsables = [],
  ganttAPI = customProPlannerGanttRef,
  updateAsyncActivity = () => {},
  setVisibleFormTags = () => {},
  updateAsyncTask = () => {},
  listViews = [],
  lastLevelActivities = [],
  massiveSelectionGannt = () => {},
  setMassiveSelectionGannt = () => {},
  setMassiveSelection = () => {},
  setVisibleFormSubcontract = () => {},
  massiveSelection = () => {},
  deleteSubcontract = () => {},
  showDrawer = () => {},
  setVisibleFormResource = () => {},
  setTypeResource = () => {},
  setIsWeeklyCommited = () => {},
  isWeeklyCommited = false,
  volatileTaskData = [],
  customHour = {},
  massiveSelectionHandler = () => {},
  resizeColumnsTimeLine = () => {},
  saveColumnOrderTimeline = () => {},
  defaultView
}) {
  /**
   * COMPONENT VARIABLES SECTION
   */
  /** props */
  const [resources, setResources] = useState(lastLevelActivities?.resource);
  const [didGanttParsed, setDidGanttParsed] = useState({
    value: false
  });
  const [waitSaveWeekly, setWaitSaveWeekly] = useState(false);
  const [weeklyRangeObject, setWeeklyRangeObject] = useState(null);
  const originalWeekly = useWeeklyplan(customHour);
  const forwardWeeklyObject = useCheckForwardWeekly(customHour);
  const timelineState = useSelector((state) => state.timelineState);
  const history = useHistory();
  const [didFirstLoad, setDidFirstLoad] = useState(false);
  window.setDidFirstLoad = setDidFirstLoad;

  if (gantt) {
    gantt.resetSectorForwardData = (_) => {
      saveForwardWeeklyData(null, true);
    };
  }

  useEffect(() => {
    if (gantt && materials) gantt.materials = materials;
  }, [gantt, materials]);

  useEffect(() => {
    if (gantt && specialties) gantt.specialties = specialties;
  }, [gantt, specialties]);

  useEffect(() => {
    if (gantt && machineries) gantt.machineries = machineries;
  }, [gantt, machineries]);

  useEffect(() => {
    if (gantt && subContracts) gantt.subContracts = subContracts;
  }, [gantt, subContracts]);

  useEffect(() => {
    // PP-1592: Cant copy responsables
    if (gantt && toSelectResponsables)
      gantt.toSelectResponsables = toSelectResponsables;
  }, [gantt, toSelectResponsables]);

  useEffect(() => {
    // PP-1592: Cant copy tags
    if (gantt && toSelectTags) gantt.toSelectTags = toSelectTags;
  }, [gantt, toSelectTags]);

  useEffect(() => {
    if (!forwardWeeklyObject || !originalWeekly) return;
    if (forwardWeeklyObject.shouldGoForward) {
      setWeeklyRangeObject(forwardWeeklyObject);
    } else {
      setWeeklyRangeObject(originalWeekly);
    }
    setWeeklyRange();
  }, [forwardWeeklyObject, originalWeekly, timelineState]);

  useEffect(
    () => () => {
      try {
        if (window.ganttVisualization) {
          window.ganttVisualization.destructor();
        }
      } catch (e) {
        console.log('e', e);
      }
      delete window.ganttVisualization;
    },
    []
  );
  /** consts */
  const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
  window.ganttVisualization = gantt;
  window.ganttVisualization.dataHardcoded = data;
  window.ganttVisualization.activities = lastLevelActivities?.activities || [];

  /** hooks */
  const [onlyRead, setOnlyRead] = useState(permission == 'V');
  const [ganttContainer, setGanttContainer] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [toDisable, setToDisable] = useState([]);
  const [loadingExport, setLoadingExport] = useState(false);
  const { height, width } = useWindowDimensions();
  const [editedInput, setEditedInput] =
    useState(null); /** handle state of editable input  */
  const [popsVisibility, setPopsVisibility] = useState({});

  const [showModal, setShowModal] = useState(false);
  const [selectedParent, setSelectedParent] = useState(null);

  /** Weekly beahviour integration */
  const [tasksWeeklyPlan, setTasksWeeklyPlan] = useState({
    value: []
  });
  const [localActivitiesMap, setLocalActivitiesMap] = useState({});

  /** redux states */
  const lookaheadState = useSelector((state) => state[stateToExtract]);
  const projectState = useSelector((state) => state.projectState);
  const stateCompany = useSelector((state) => state.companyState);
  const dispatch = useDispatch();
  const copyPasteState = useSelector((state) => state.copyPasteState);

  const timerManager = TimerManagerSingleton.getInstance();

  const ranges = Object.keys(markersDates).map((key) => ({
    date: markersDates[key],
    label: key + ' of Lookahead'
  }));
  const [modalModification, setModalModification] = useState({
    visible: false,
    title: '',
    description: '',
    data: { activity: {}, task: {} }
  });

  const [weeklyModalData, setWeeklyModalData] = useState({
    visible: false,
    tasksToCommit: []
  });
  const [weeklyStatusObject, setWeeklyStatusObject] = useState({});
  const subtradeRole = useSubtradeUser();

  /**
   * EFFECTS COMPONENT SECTION
   */

  useEffect(() => {
    if (gantt) {
      gantt.isWeeklyCommited = isWeeklyCommited.commited;
      gantt.reactHistory = history;
      gantt.config.readonly = onlyRead;
    }
  }, [isWeeklyCommited, gantt]);

  /** Effect to load translation to table declaration file */
  useEffect(() => {
    const loadTranslationTableCallback = () => {
      tableMetadata.forEach((column) => {
        if (
          column.data_type.includes('/icon') ||
          column.data_type.includes('/string')
        ) {
          column.label = t('tables.lookahead.plan.' + column.name + '.label');
          column.from_values.map((option) => {
            option.label = t(
              'tables.lookahead.plan.' +
                column.name +
                '.options.' +
                option.value
            );
          });
        } else {
          column.label = t('tables.lookahead.plan.' + column.name);
        }
      });
    };
    timerManager.registerAutoTimeout(
      loadTranslationTableCallback,
      300,
      'loadTranslationTableTimer'
    );
    setMassiveSelectionGannt({});
  }, []);

  useEffect(() => {
    if (gantt) {
      gantt.addConstraint = (id) => {
        const task = gantt.getTask(id);
        handleAddConstraint(task);
      };

      gantt.customExportToPDF = (exportObject) => {
        const currentScale = gantt.ext.zoom.getCurrentLevel();

        setLoadingExport(true);
        gantt.ext.zoom.setLevel('Month-Week');

        const exportToPDFCallback = () => {
          gantt.exportToPDF(exportObject);
          gantt.ext.zoom.setLevel(currentScale);
        };
        timerManager.registerAutoTimeout(
          exportToPDFCallback,
          250,
          'exportToPDFTimer'
        );

        const setLoadingExportCallback = () => {
          setLoadingExport(false);
        };
        timerManager.registerAutoTimeout(
          setLoadingExportCallback,
          500,
          'setLoadingExportTimer'
        );
      };

      gantt.addTaskChildConstraint = (parentToRecursiveId) => {
        const finalArray = [];
        recursiveIdentifyTasks(parentToRecursiveId, finalArray);
        handleAddConstraint(finalArray);
      };

      gantt.dealWithModifications = (item_id) => {
        const task = gantt.getTask(item_id);
        handleUnfit(task);
      };
      gantt.t = t;
      gantt.subContracts = subContracts;
      gantt.shouldOnlyRead = onlyRead;
    }
  }, [gantt]);

  useEffect(() => {
    if (data?.data?.length) {
      if (gantt) {
        const loadDataCallback = async () => {
          try {
            /** Memory leak 3 */
            if (!timelineState.value) {
              gantt.clearAll();
              window.ganttData = data;
              gantt.parse(data);
              dispatch(timelineActions.updateParsedValue(true));
              gantt.changeVisualizationOption &&
                gantt.changeVisualizationOption();

              handleWeeklyRangeOnDataChange({
                gantt,
                weeklyRangeObject
              });
            } else {
              const hashMapGanttData = {};
              // eslint-disable-next-line no-return-assign
              data.data.forEach((el) => (hashMapGanttData[el.id] = el));
              window.ganttData = data;
              window.hashMapGanttData = hashMapGanttData;
              // console.log('Should update data instead re parsing')
            }
          } catch (e) {
            console.log(e);
          }
        };
        timerManager.registerAutoTimeout(
          loadDataCallback,
          800,
          'loadDataTimer'
        );
      }
    }
  }, [data, timelineState]);

  useEffect(() => {
    if (weeklyRangeObject && weeklyRangeObject.initialStatus) {
      weeklyRangeObject.initialStatus(lastLevelActivities);
    }
    return () => {};
  }, [weeklyRangeObject, gantt]);

  useEffect(() => {
    if (massiveSelectionGannt.activityReferenceId) {
      /** is task */
      const activityReference = window.activities.find(
        (ac) => ac.id == massiveSelectionGannt.activityReferenceId
      );
      if (activityReference) {
        const doesExistAtReference = getTask(
          massiveSelectionGannt.taskId,
          null,
          activityReference
        );
        massiveSelectionHandler(doesExistAtReference[0]);
      }
    } else {
      /** is activity */
      const arrLstLvlAct = [];
      findLastLevelActivityTree(massiveSelectionGannt.taskId, arrLstLvlAct);

      if (arrLstLvlAct.length) {
        arrLstLvlAct.forEach((elId) => {
          let field_to_find = 'unique_id';
          if (
            groupBy.criteria != 'activity' &&
            groupBy.criteria != 'activityId'
          ) {
            field_to_find = 'id';
          }
          const activityReference = findDeep(activities, field_to_find, elId);
          if (activityReference) {
            massiveSelectionHandler(activityReference);
          }
        });
      }
    }
  }, [massiveSelectionGannt]);

  useEffect(() => {
    if (!gantt) return;
    gantt.subtradeRole = subtradeRole;
  }, [subtradeRole]);

  /** Similar to componentDidMount */
  useEffect(() => {
    const sector = projectState.allSectors.find(
      (e) => e.id == projectState.sectorSelected
    );

    /**
     * If a effect hoks means on
     * subscription, must return a
     * clean function to unsubscribe it
     * check docs at https://es.reactjs.org/docs/hooks-effect.html
     */
    if (ganttContainer && !loaded) {
      /** NOTE: Working but not loading config with this method (shoudl study case) */
      const currentIdxView =
        stateToExtract === 'lookaheadState'
          ? listViews.current.findIndex(findView)
          : -1;
      let ganttEvents;

      try {
        ganttEvents = ganttConfig(
          gantt,
          ganttContainer,
          projectState,
          dispatch,
          t,
          sector,
          tableMetadata,
          onDoubleClickTask,
          ranges,
          {
            lookaheadState,
            currentIdxView,
            ganttChart: listViews.current[currentIdxView].ganttChart
          },
          activities,
          updateGanttVisualizationState,
          subContracts,
          setVisibleFormSubcontract,
          setMassiveSelectionGannt,
          groupBy,
          updateTaskDrag,
          {
            resizeColumnsTimeLine: resizeColumnsTimeLine,
            saveColumnOrder: saveColumnOrderTimeline
          },
          setWeeklyModalData,
          setMassiveSelection,
          copyPasteState
        );
        gantt.Sentry = Sentry;
      } catch (e) {
        // console.log('gantt view: errorr')
        console.log(e);
      }

      configExportPlugin(gantt, t);
      setLoaded(true);
      setGanttLoaded(true);
      return () => {
        // detach all saved events
        while (ganttEvents?.length) {
          gantt.detachEvent(ganttEvents.pop());
        }
        try {
          // props.gantt.destructor()
        } catch (e) {
          // console.log('gantt view: errorr111')
          console.log(e);
        }
      };
    }

    /**
     * Giving an object param array,
     * you must specify that only on
     * it change this effect will be executed
     */
  }, [ganttContainer, gantt]);

  useEffect(() => {
    if (stateToExtract === 'lookaheadState' && listViews.current.length) {
      let currentView = listViews.current.find(findView);
      currentView = currentView || listViews.current[0];
      return showGanttColumns(
        currentView.view.columns_json.columnsActive || defaultGanttFields
      );
    }

    showGanttColumns(lookaheadState?.activatedColumns);
  }, [toDisable]);

  useEffect(() => {
    if (tableMetadata.length) {
      if (stateToExtract === 'lookaheadState') {
        const currentView = listViews.current.find(findView);
        const ganttColumns =
          currentView?.view.columns_json.columnsActive || defaultGanttFields;
        return GanttViewActivatedColumns(ganttColumns);
      }

      /** This section code deal when redux has a state, turning on those ones, and disabling which not */
      if (lookaheadState?.activatedColumns?.length) {
        return GanttViewActivatedColumns(lookaheadState.activatedColumns);
      }

      /** This code deals with no redux state, and use default config from gantt-columns.js */
      const activated = tableMetadata.filter((col) => !col.hide);
      dispatch(actions.setActivatedColumns(activated));
    }
  }, [tableMetadata]);

  /**
   * FUNCTIONS COMPONENT SECTION
   */

  const loadZoomView = (zoomLevel) => {
    const stringValues = {
      YEARS: 0,
      DAYS: 4,
      WEEKS: 3,
      MONTH: 2,
      QUARTERS: 1
    };
    let finalLevelNumber;
    const indexToLoadZoom = stringValues[zoomLevel];
    if (indexToLoadZoom === 0) {
      finalLevelNumber = 0;
    } else if (!indexToLoadZoom) {
      finalLevelNumber = 4;
    } else {
      finalLevelNumber = indexToLoadZoom;
    }
    gantt.ext.zoom.setLevel(finalLevelNumber);
  };

  const loadBarColorView = (barColor) => {
    window.changeBarColor(barColor || 'status');
  };

  useEffect(() => {
    if (didFirstLoad) {
      try {
        window.viewAlreadyLoaded = true;
        const defaultView = window.defaultView && window.defaultView.current;
        const { zoomLevel, barColor, collapsed } = defaultView;

        loadBarColorView(barColor);
        loadZoomView(zoomLevel);
      } catch (e) {
        Sentry.captureMessage(e, 'warning');
      }
    }
  }, [didFirstLoad]);

  const debounceDidFirstLoad = debounce(() => {
    setDidFirstLoad(true);
  }, 50);

  const setWeeklyRange = async (_) => {
    if (!gantt) return;
    gantt.batchUpdate(async () => {
      try {
        if (
          gantt &&
          gantt.addMarker &&
          weeklyRangeObject &&
          weeklyRangeObject.checkIfExistsWeeklyPlan
        ) {
          gantt.weeklyRangeObject = weeklyRangeObject;

          const currentWeek = getWeekNumberFromStartWeek(
            gantt.weeklyRangeObject?.range?.start
          );
          const currentYear = getYearFromThursday(
            weeklyRangeObject.range.start
          );

          const weeklyplanStatusObject = {
            week: currentWeek,
            year: currentYear,
            isCommited: await weeklyRangeObject.checkIfExistsWeeklyPlan(
              currentWeek,
              currentYear,
              projectState.sectorSelected
            ),
            start: weeklyRangeObject.range.start,
            end: weeklyRangeObject.range.end
          };

          setWeeklyStatusObject(weeklyplanStatusObject);
          setIsWeeklyCommited({
            commited: weeklyplanStatusObject.isCommited !== false
          });
          gantt && (gantt.isWeeklyCommited = weeklyplanStatusObject.isCommited);
          gantt && (gantt.weeklyplanStatusObject = weeklyplanStatusObject);

          handleWeeklyRangeOnDataChange({ gantt, weeklyRangeObject });

          const accumulatedTasks = [];

          gantt.getTaskByTime().forEach((t) => {
            gantt.checkIfTaskIsInsideOfWeekly(t, true, accumulatedTasks);
          });

          updateTaskMassive(accumulatedTasks);
        }
      } catch (e) {
        Sentry.captureMessage(e, 'warning');
      }
      debounceDidFirstLoad();
    }, true);
  };
  gantt && (gantt.setWeeklyRange = setWeeklyRange);

  const dropdownRemoveCallback = (task, column) => (_) => {
    const taskFromLookahead = findDeepGetTask(activities, 'id', task.id);
    if (column.name === 'tags') {
      updateAsyncTaskGanttV2(
        taskFromLookahead,
        column.name,
        [],
        gantt,
        true,
        false,
        true
      );
    } else {
      updateAsyncTaskGanttV2(
        taskFromLookahead,
        column.name,
        null,
        gantt,
        true,
        false,
        true
      );
    }
  };

  const getReferenceFromLookahead = (ganttRefTask) => {
    try {
      const task = ganttRefTask;
      const activityReferenceLvl = activities.find(
        (ac) =>
          ac.id ==
          (task?.activityReference?.proplannerId || task?.activityReference?.id)
      );
      const newLookaheadTaskRef = getTask(task.id, null, activityReferenceLvl);
      if (newLookaheadTaskRef?.length) {
        return newLookaheadTaskRef[0];
      }
      return false;
    } catch (e) {
      console.log('Error on getReferenceFromLookahead');
      console.log(e);
      return false;
    }
  };

  /**
   * This function assign a value from the gantt state, to planification view state reference
   * @param {*} task Task object from DTHMLX gantt
   * @param {*} value new value to set at column definiton
   * @param {*} column definiton to assign the given value
   */
  const assignValueFromGanttToLookaheadRef = (task, value, column) => {
    try {
      if (!task || !value || !column) return;
      task[column] = value;
      const ganttObject = getReferenceFromLookahead(task);
      if (ganttObject) {
        ganttObject[column] = value;
        gantt.optimizedRender && gantt.optimizedRender();
        updateAsyncTask(ganttObject);
      }
    } catch (e) {
      console.log('Error on assignValueFromGanttToLookaheadRef');
      console.log(e);
    }
  };

  /**
   * This function execute an complete recalculate of progress for the tree, from one specific node
   * @param {*} dhtmlxTask Task reference comming from GANTT DHTMLX
   * @param {*} parent Parent object comming from PLANIFICATION VIEW
   * @param {*} activity Activity comming from PLANIFICATION VIEW
   * @param {*} column column, comming from table.layout definition
   * @param {*} newProgress new progress to start get new value
   */
  const calculateProgressForTree = (
    dhtmlxTask,
    parent,
    activity,
    column,
    newProgress
  ) => {
    const task = getReferenceFromLookahead(dhtmlxTask);

    task[column.name] = newProgress;
    if (task[column.name] == 0) {
      if (calculatePonderators) {
        calculatePonderators(
          parent || activity,
          activity,
          (t) => {
            updateAsyncTaskGanttV2(t, 'ponderator', t.ponderator, gantt);
            updateAsyncTask(t);
          },
          projectState
        );
      }
    }

    updateAsyncTask(task);
    if (parent) {
      calculateProgress(
        task,
        parent,
        activity,
        (t) => {
          updateAsyncTaskGanttV2(t, 'progress', t.progress, gantt);
          updateAsyncTask(t);
        },
        (activity) => {
          const ganttOb = gantt.getTask(activity.unique_id);
          if (ganttOb) {
            ganttOb.progress = activity.progress;
            gantt.optimizedRender && gantt.optimizedRender();
          }
          updateAsyncActivity(activity);
        }
      );
    }
  };

  const recursiveIdentifyTasks = (parentId, finalArray) => {
    const childs = gantt.getChildren(parentId);
    if (childs.length) {
      childs.map((idChild) => {
        const objectItem = gantt.getTask(idChild);
        if (objectItem.type == 'activitytask') {
          finalArray.push(objectItem);
        }
        recursiveIdentifyTasks(objectItem.id, finalArray);
      });
    }
  };

  const findDeep = (data, fieldToFind, value) =>
    data.find((e) => {
      if (e[fieldToFind] && e[fieldToFind] == value) return true;
      else if (e.tasks) return findDeep(e.tasks, fieldToFind, value);
    });

  /**
   * This function find last level activities in activities tree structure
   * @param {*} idAct Node Activity Id
   * @param {*} lastlevelacts Array of last level activities Ids
   */
  const findLastLevelActivityTree = (idAct, lastlevelacts) => {
    /** get childrens */
    const childs = gantt.getChildren(idAct);
    if (childs.length) {
      let allChildsAreTask = true;
      childs.forEach((ch) => {
        const task = gantt.getTask(ch);
        if (!task.isTask && allChildsAreTask) {
          allChildsAreTask = false;
        }
      });
      if (allChildsAreTask) {
        lastlevelacts.push(idAct);
      } else {
        childs.forEach((ch) => {
          findLastLevelActivityTree(ch, lastlevelacts);
        });
      }
    }
  };

  const updateTaskDrag = async (id, gantt_task, mode) => {
    /** get activity, task, and parent references */
    const { activityReference, taskFromLookahead, parentTask } =
      getReferences(gantt_task);

    taskFromLookahead.start_date = gantt_task.start_date;
    taskFromLookahead.end_date = gantt_task.end_date;

    if (mode === 'resize') {
      taskFromLookahead.duration = transformHourToDays(gantt_task.duration);
    }
    try {
      updateTaskDragSave(
        taskFromLookahead,
        gantt,
        projectState,
        activityReference,
        parentTask,
        ganttAPI
      );
    } catch (e) {
      console.log(e);
    }
  };

  const findView = ({ view, sector, project }) => {
    if (defaultView.id == view.id) return true;
  };

  const GanttViewActivatedColumns = (columnsSettings) => {
    const activated = columnsSettings.filter((op) => {
      const col = tableMetadata.filter((c) => c.name == op);
      if (col.length != 0) {
        return { label: col[0].label, value: col[0].name };
      }
    });

    const allColumns = [];
    tableMetadata.forEach((el) => {
      if (!el.ignore_as_column && !el.ignoreGantt) allColumns.push(el.name);
    });

    /** difference between arrays (all/active) to disable */
    const colsToDisable = allColumns.filter(
      (columnName) => !activated.includes(columnName)
    );
    // const colsToEnable = allColumns.filter(columnName => activated.includes(columnName));
    setToDisable(colsToDisable);
  };

  const showGanttColumns = (activatedColumns) => {
    try {
      activatedColumns.forEach((columnName) => {
        if (gantt.getGridColumn(columnName)) {
          gantt.getGridColumn(columnName).hide = false;
        }
      });

      toDisable.forEach((columnName) => {
        if (gantt.getGridColumn(columnName)) {
          gantt.getGridColumn(columnName).hide = true;
        }
      });
      gantt.optimizedRender && gantt.optimizedRender();
    } catch (e) {
      console.log(e);
    }
  };

  const commitWeeklyPlan = async (_) => {
    setWaitSaveWeekly(true);
    const tasksToCommitFromServer =
      await taskService.getAllTasksInsideWeeklyRange({
        start: weeklyStatusObject.start,
        end: weeklyStatusObject.end,
        sectorId: projectState.sectorSelected
      });
    if (!tasksToCommitFromServer) return;

    const { tasksToCommit } = weeklyModalData;
    const lookaheadRefArray = [];
    if (tasksToCommit && tasksToCommit.forEach) {
      tasksToCommit.forEach((t) => {
        if (
          !t.is_milestone &&
          (!t.inside_weekly_other_lean ||
            (t.inside_weekly_other_lean && t.lean_status === 'Will'))
        ) {
          const tFromLookahead = getReferenceFromLookahead(t);
          lookaheadRefArray.push({
            lookaheadRef: tFromLookahead,
            ganttRef: t
          });
        }
      });
    }

    /** PATCH: We now are able to get tasks from server which show whole elements inside the weekly plan */
    const visibleAndNoVisibleTasks = [];
    const alreadyDoneIds = {};
    const dateRange = {
      start: weeklyStatusObject.start,
      end: weeklyStatusObject.end
    };

    lookaheadRefArray.forEach((el) => {
      if (!alreadyDoneIds[el.lookaheadRef.id]) {
        const activityRef = activities.find(
          (activity) => activity.id === el.lookaheadRef.activityId
        );
        el.lookaheadRef.activity = activityRef;
        gantt.weeklyRangeObject.calculateCommitmentPercentaje(
          el.lookaheadRef,
          dateRange
        );
        el.ganttRef.commitment_percentaje =
          el.lookaheadRef.commitment_percentaje;
        delete el.lookaheadRef.activity;
        alreadyDoneIds[el.lookaheadRef.id] = true;
        visibleAndNoVisibleTasks.push(el.lookaheadRef);
      }
    });

    /** NOTE: Here must be checked the commitment_percentaje, because the other ones were already calculated by gantt API */
    tasksToCommitFromServer.tasks.forEach((el) => {
      if (!alreadyDoneIds[el.id]) {
        alreadyDoneIds[el.id] = true;
        gantt.weeklyRangeObject.calculateCommitmentPercentaje(el, dateRange);
        delete el.activity;
        visibleAndNoVisibleTasks.push(el);
      }
    });

    const week = getWeekNumberFromStartWeek(
      gantt.weeklyRangeObject?.range?.start
    );
    const saveWeekly = await weeklyRangeObject.saveWeeklyPlan(
      visibleAndNoVisibleTasks,
      week,
      dateRange,
      projectState.projectSelected,
      projectState.sectorSelected,
      stateCompany.currentCompany
    );
    if (saveWeekly) {
      dispatch(userActions.setUserUpdate());
      lookaheadRefArray.forEach(({ lookaheadRef, ganttRef }) => {
        ganttRef.lean_status = 'Committed';
        lookaheadRef.lean_status = ganttRef.lean_status;
        workerService.callAction(
          save_single_task_lookahead,
          lookaheadRef,
          () => {
            taskService.update(lookaheadRef);
          }
        );
      });

      trackingEvent(
        'commit_weekly_plan',
        {
          ...getBasicAmplitudEventProperties(),
          task_commited_number: lookaheadRefArray?.length
        },
        AMPLITUDE_SERVICE
      );

      setIsWeeklyCommited({ commited: true });
      setWeeklyModalData({
        visible: false,
        tasksToCommit: []
      });
      if (gantt && gantt.runAndUnmountTimeout) {
        gantt.runAndUnmountTimeout(async () => {
          await setWeeklyRange();
        });
      }
    } else {
      openNotification({
        /** notification error */ title: t(
          'new_weekly_lookahead.commit_confirm'
        ),
        description:
          t('lang') === 'es'
            ? 'No tienes tareas para comprometer en la semana'
            : 'You have no task to commit for the current week.',
        type: 'warning'
      });
    }
    setWaitSaveWeekly(false);
  };

  const renderWeeklyCommitModal = () => (
    /* Modal for creating custom ponderator */
    <Modal
      width={320}
      wrapClassName="lookahead-weekly-modal"
      title=""
      visible={weeklyModalData.visible}
      onCancel={() =>
        setWeeklyModalData({
          visible: false,
          tasksToCommit: []
        })
      }
      footer={[]}>
      <Row>
        <Col style={{ marginTop: 19 }}>
          <Row>
            <Col span={24} className="col-content-img-container">
              <img
                src={ModalWeeklyContentImg}
                width={48}
                height={36}
                className="img-content-weekly-modal"
              />
            </Col>
          </Row>
          <Row style={{ marginTop: 26 }}>
            <Col span={24} className="col-content-msg-container">
              {t('new_weekly_lookahead.modal_content_msg')}
            </Col>
          </Row>
          <Row style={{ marginTop: 20 }}>
            <Col span={12} className="col-btn-modal-container">
              <button
                className="cancel-btn-modal-weekly"
                onClick={() =>
                  setWeeklyModalData({
                    visible: false,
                    tasksToCommit: []
                  })
                }>
                {t('register.cancel')}
              </button>
            </Col>
            <Col span={12}>
              <button
                className="confirm-commit-modal-btn"
                disabled={waitSaveWeekly}
                onClick={commitWeeklyPlan}>
                {t('new_weekly_lookahead.commit_confirm')}
              </button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
  buildReactRender({
    activities,
    gantt,
    t,
    onlyRead,
    materials,
    lastLevelActivities,
    dropdownRemoveCallback,
    setActivityResource,
    setTypeResource,
    setVisibleFormResource,
    machineries,
    tableMetadata,
    volatileTaskData,
    specialties,
    toSelectSubs,
    setVisibleFormSubcontract,
    toSelectTags,
    setVisibleFormTags,
    toSelectResponsables,
    setEditedInput,
    projectState,
    updateAsyncActivity,
    updateGanttVisualizationState,
    data,
    showDrawer,
    updateRender,
    assignValueFromGanttToLookaheadRef,
    calculateProgressForTree,
    updateAsyncTask,
    editedInput,
    resources,
    weeklyStatusObject,
    showModal,
    setShowModal,
    selectedParent,
    setSelectedParent,
    popsVisibility,
    setPopsVisibility,
    ganttAPI,
    modalModification,
    setModalModification,
    groupBy,
    tasksWeeklyPlan,
    setTasksWeeklyPlan,
    localActivitiesMap,
    setLocalActivitiesMap,
    weeklyRangeObject,
    updateTaskDrag
  });
  /** calculate heigth after selection massive */
  const HEIGHT_OFFSET = 222;
  const heightCalculated = height - HEIGHT_OFFSET;

  return (
    <div data-testid={TestIds.GANTT_VISUALIZATION_CONTAINER}>
      {loadingExport ? (
        <span className="spin-hide-export">
          <Spin indicator={antIcon} />
        </span>
      ) : null}
      <div
        className="gantt-visualization"
        ref={(input) => {
          setGanttContainer(input);
        }}
        style={{ width: '100%', height: heightCalculated, zIndex: 1 }}></div>
      {renderWeeklyCommitModal()}
      {renderModalRequestModification(
        modalModification,
        setModalModification,
        t,
        dispatch,
        projectState,
        userActions
      )}
      {renderModalCustomPonderator(
        showModal,
        setShowModal,
        selectedParent,
        setSelectedParent,
        updateAsyncActivity,
        updateAsyncTask,
        gantt,
        t
      )}
    </div>
  );
}

export default withTranslation()(GanttVisualization);
