import {
  getIsOdd,
  getIsOnlyReadElement,
  getTaskFromLookahead
} from '../common/validators';
import {
  prepareUIElements,
  registerTaskCallbacks,
  setupGlobalHandlers
} from './utils';
import dropdownHTML from '../../components/dropdownHTML';
import './index.scss';

const renderPriority = (props) => {
  const {
    gantt,
    activities,
    column,
    onlyRead,
    task,
    mainObject,
    callbackOnSelection
  } = props;
  setupGlobalHandlers();

  if (!task.isTask && gantt) return '';

  const taskFromLookahead = getTaskFromLookahead(activities, gantt, task.id);
  const { subtradeRole } = gantt;
  const isOnlyReadElement = getIsOnlyReadElement(onlyRead, task, subtradeRole);
  const isOdd = getIsOdd({ gantt, task });
  const selectedObject = mainObject || task;
  const optionsList = column.from_values;

  registerTaskCallbacks(task.id, task, {
    taskFromLookahead,
    column,
    gantt,
    callbackOnSelection
  });

  const { placeholderHtml, optionsListHtml } = prepareUIElements({
    selectedObject,
    optionsList,
    isOnlyReadElement,
    column,
    task
  });

  const dropdownContent = `
    <ul class="options-list " onscroll="event.stopPropagation()">
      ${optionsListHtml}
    </ul>
  `;

  const dropdown = dropdownHTML({
    trigger: placeholderHtml,
    content: dropdownContent,
    width: column?.width,
    isReadOnly: isOnlyReadElement,
    id: `priority-dropdown-${task.id}`,
    customClass: `custom-priorities-dropdown`,
    searchable: false,
    searchPlaceholder: 'Search'
  });

  return `
    <div 
      class="${isOdd ? 'odd-col' : 'non-odd-col'}"
      data-task-id="${task.id}"
      data-column-name="${column.name}">
      ${dropdown}
    </div>
  `;
};

export default renderPriority;
