import TooltipHTML from '../../../TooltipHTML';
import { capitalize } from 'lodash';

export const renderPriorityToSelect = (option) => {
  const { object } = option;

  return `
    <div class="item-gantt " >
      <img src="${object?.icon}" alt="${object?.label}" width="15" height="15" />
      ${capitalize(object?.label)}
    </div>
  `;
};

export const renderSelectedOption = (selected, isOnlyReadElement, task) => {
  const object = selected;
  const tooltipText = capitalize(object?.label) || '';

  return `
    ${TooltipHTML({
      text: tooltipText,
      container: `
        <div 
          class="item-gantt selected-option-container-priority  ${isOnlyReadElement ? 'read-only' : 'clickable'}">
          <div>
            <div class="item-gantt" style="display: flex; align-items: center;">
              <img src="${object?.icon}" alt="${object?.label}" width="12" height="12" />
            </div>
          </div>
        </div>
      `,
      task_id: task?.id || 0,
      name: 'selected-option-tooltip'
    })}
  `;
};
