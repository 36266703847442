import {
  getIsOdd,
  getIsOnlyReadElement,
  getTaskFromLookahead
} from '../common/validators';
import {
  prepareUIElements,
  registerTaskCallbacks,
  setupGlobalHandlers
} from './utils';
import dropdownHTML from '../../components/dropdownHTML';

const renderCompanies = (props) => {
  setupGlobalHandlers();

  const {
    column,
    activities,
    gantt,
    t,
    onlyRead,
    task,
    subs,
    callbackOnClickCreate,
    callbackOnClickRemove,
    callbackOnSelection,
    mainObject,
    options
  } = props;

  if (!task.isTask && gantt) return '';

  const taskFromLookahead = getTaskFromLookahead(activities, gantt, task.id);
  const { subtradeRole } = gantt;
  const isOnlyReadElement = getIsOnlyReadElement(onlyRead, task, subtradeRole);
  const isOdd = getIsOdd({ gantt, task });
  const selectedObject = mainObject || task;
  const optionsList = options || subs;
  const selectedSub = optionsList.find(
    (companies) => String(companies.id) === String(selectedObject.subcontractId)
  );

  registerTaskCallbacks(task.id, task, {
    taskFromLookahead,
    column,
    gantt,
    callbackOnSelection,
    callbackOnClickCreate,
    callbackOnClickRemove
  });

  const { placeholderHtml, optionsListHtml, createBtnHtml, removeBtnHtml } =
    prepareUIElements({
      selectedObject,
      optionsList,
      isOnlyReadElement,
      t,
      column
    });

  const dropdownContent = `
    <ul class="options-list" onscroll="event.stopPropagation()">
      ${createBtnHtml}
      ${optionsListHtml}
      ${removeBtnHtml}
    </ul>
  `;

  const dropdown = dropdownHTML({
    trigger: placeholderHtml,
    content: dropdownContent,
    width: column?.width,
    isReadOnly: isOnlyReadElement,
    id: `companies-dropdown-${task.id}`,
    customClass: `custom-companies-dropdown`,
    searchable: true,
    searchPlaceholder: selectedSub?.name
  });

  return `
    <div 
      class="${isOdd ? 'odd-col' : 'non-odd-col'}"
      data-task-id="${task.id}"
      data-column-name="${column.name}">
      ${dropdown}
    </div>
  `;
};

export default renderCompanies;
