import { findDeepGetTask } from '../../GanttVisualization.helper';

export const getIsOnlyReadElement = (onlyRead, task, subtradeRole) =>
  onlyRead || task.readonly || (subtradeRole && subtradeRole.isSubtradeRole);

export const getIsOdd = ({ gantt, task }) =>
  gantt?.oddColsConfig && task.type === 'activitytask'
    ? gantt.oddColsConfig.subcontractId
    : false;

export const getTaskFromLookahead = (activities, gantt, taskId) =>
  activities && gantt ? findDeepGetTask(activities, 'id', taskId) : null;
