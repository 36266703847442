import { generateRandomId, initDropdownManager } from './utils';
import searchIcon from '../../../../assets/img/gantt/search-new.png';
import i18n from '../../../../i18n';

const getFinalContent = (config) => {
  const { content, searchable, searchPlaceholder } = config;
  return searchable ? createSearchContent(searchPlaceholder, content) : content;
};

const createSearchContent = (searchPlaceholder, content) => {
  const searchBar = `
    <div class="dropdown-search">
      <input 
        type="text" 
        class="dropdown-search-input" 
        placeholder="${searchPlaceholder}"
      />
      <img src="${searchIcon}" alt="search" class="search-icon" />
    </div>
  `;
  return searchBar + content;
};

const createDropdownHTML = (options) => {
  const {
    dropdownId,
    trigger,
    finalContent,
    isReadOnly,
    customClass,
    width,
    height,
    searchable,
    searchItemSelector
  } = options;
  const readOnlyClass = isReadOnly ? 'read-only' : '';
  return `
    <div class="custom-dropdown ${customClass} ${readOnlyClass}" id="${dropdownId}"
         data-width="${width}" 
         data-height="${height}" 
         data-searchable="${searchable}" 
         data-search-item-selector="${searchItemSelector}">
      <div class="custom-dropdown-trigger">
        ${trigger}
      </div>
      <div class="dropdown-content" style="display: none;">
        ${finalContent}
      </div>
    </div>
  `;
};

/**
 * Creates a HTML dropdown component with various configuration options
 *
 * @param {Object} options - The configuration options for the dropdown
 * @param {string|HTMLElement} options.trigger - The element that triggers the dropdown
 * @param {string} options.content - The HTML content to display inside the dropdown
 * @param {string|number} [options.width='180px'] - The width of the dropdown
 * @param {string|number} [options.height='224px'] - The maximum height of the dropdown
 * @param {boolean} [options.isReadOnly=false] - Whether the dropdown is read-only (not clickable)
 * @param {string|null} [options.id=null] - Custom ID for the dropdown, generated randomly if not provided
 * @param {string} [options.customClass=''] - Additional CSS classes to apply to the dropdown
 * @param {boolean} [options.searchable=false] - Whether to include a search input in the dropdown
 * @param {string} [options.searchPlaceholder] - Placeholder text for the search input
 * @param {string} [options.searchItemSelector='li, .dropdown-item'] - CSS selector for items to be searched
 * @returns {string} HTML string representing the dropdown component
 */
const dropdownHTML = (options) => {
  initDropdownManager();
  const {
    trigger,
    content,
    width = '180px',
    height = '224px',
    isReadOnly = false,
    id = null,
    customClass = '',
    searchable = false,
    searchPlaceholder = i18n.t('master_plan.search'),
    searchItemSelector = 'li, .dropdown-item'
  } = options;
  const dropdownId = id || generateRandomId();

  const finalContent = getFinalContent({
    content,
    searchable,
    searchPlaceholder
  });

  return createDropdownHTML({
    dropdownId,
    trigger,
    finalContent,
    height,
    isReadOnly,
    customClass,
    width,
    searchable,
    searchItemSelector
  });
};

export default dropdownHTML;
