import React from 'react';

/** import services */
import { taskService, activityService } from '../../services';

/** import lodash utilities */
import cloneDeep from 'lodash/cloneDeep';

import { getTask, priorityArray } from '../../utils/lookahead-common';
/** Import elements from library Antd */
import { Popover, Tooltip, Input, Popconfirm, Icon, Modal, Button } from 'antd';

/** import library for tracker appcues */
import { trakerServiceAppCues } from '../../utils/appcues-util';

import {
  convertPPTask2PluginTask,
  findDeepGetActivityOfTask
} from '../../views/lookahead/planification/index.helper';

import { base as baseAPI } from '../../services/base';
/** SVG image ponderatior icons */
import rollbackPonderatorInvertedIcon from '../../assets/img/newlookahead/ponderator/comeback.png';
import {
  helmet,
  openNotification,
  getDynamicAttributesToCreateTask
} from '../../utils';

/** import totango utils for tracker actions */

import { activityModificationService } from '../../services';

import calendarModificationIconModal from '../../assets/img/activitymodification/calendar-modal.png';

import {
  getRecursiveFromParentTask,
  renderFriend,
  getEndDateByGantt,
  calculatePonderators,
  calculateProgress,
  transformDateWithHour,
  getTasksToDelete,
  deleteTaskHandler,
  defineTitleModification,
  defineDateUnfit,
  createActivityModificationRequest
} from '../../utils/lookahead-common';

/** import library moment for time stuffs */
import moment from 'moment';
import {
  transformDaysToHours,
  transformHourToDays
} from '../../views/ganttContainer/gantt/gantt.helper';
import ModificationRequestsModal from '../ModificationRequestsModal';
import { totangoEventTracking } from '../../analytics/implements/totango';
import { getSignedUser } from '../../utils/userUtils';
import {
  save_massive_task_lookahead,
  save_single_task_lookahead,
  workerService
} from '../../services/serviceworker.service';
import { TimerManagerSingleton } from '../../utils/timerManager';
const { TextArea } = Input;

const timerManager = TimerManagerSingleton.getInstance();

export const avoidOptimizationTimelineBlinking = () => {
  /** This both flags with these values, let on update through the React timeline integration without blinking */
  window.ganttVisualization &&
    (window.ganttVisualization.optimizeReactRender = false);
  window.ganttVisualization &&
    (window.ganttVisualization.comesFromV2Updating = true);
};

/**
 * This function launch an update to the backend
 * @param {*} task Task on react structure data (lookahead ref)
 * @param {*} field <Array|String> Represents the column or task attribute to be updated in backend
 * @param {*} value If the fields given are not an array, this value will be assigned to the string field of that task (react and dhtmlx structure data)
 * @param {*} gantt DHTMLX gantt instance
 * @param {*} updateGantt Flag which allows to get into DHTMLX structure data ref, and update their values through the field parameter
 * @param {*} render DEPRECATED
 * @param {*} hardCodeAssign Flag which allows to force assign a value to the given field, event if it is a null value
 * @param {*} avoidRenderVisualization Flag which allows to turn on an optimization on DHTMLX render function
 * @returns nullable if there are not valid params
 */
export const updateAsyncTaskGanttV2 = async (
  task,
  field,
  value,
  gantt,
  updateGantt = true,
  render = false,
  hardCodeAssign = false,
  avoidRenderVisualization = false,
  avoidUpdateTask = false
) => {
  avoidOptimizationTimelineBlinking();
  if (!task) return;
  if (task) {
    /** update gantt's task */
    if (updateGantt) {
      if (gantt.isTaskExists(task.id)) {
        const taskGantt = gantt.getTask(task.id);
        if (Array.isArray(field)) {
          /** assign data by each field */
          field.forEach((nameField) => {
            taskGantt[nameField] = task[nameField];
          });
        } else {
          if (value !== null || hardCodeAssign) taskGantt[field] = value;
        }
      }
    }

    /** assign fields to edit */
    if (!Array.isArray(field)) {
      if (value !== null || hardCodeAssign) task[field] = value;
    }

    /** remove circular reference */
    const copy = cloneDeep(task);
    delete copy.activityObject;
    delete copy.activityReference;
    delete copy.children;
    delete copy.subcontract;

    if (value === null) {
      copy.nullifyAttribute = field;
    }

    /** Avoid saving restricted lean status */
    if (field === 'lean_status' && copy.lean_status === 'Restricted') return;

    /** update task */
    workerService.callAction(save_single_task_lookahead, copy, () => {
      taskService.update(copy);
    });

    const isRunningChangeVisualization = gantt?.attachToStack(
      copy.id,
      'updateAsyncTaskGanttV2',
      1000
    );
    // debugger
    if (!isRunningChangeVisualization) return;
    // debugger
    if (avoidRenderVisualization) {
      gantt.isRenderingOnProcess = true;
    }
    if (avoidUpdateTask) return;
    const taskCurrent = gantt.getTask(copy.id);
    if (taskCurrent) {
      const updateTaskCallback = () => {
        gantt.updateTask(taskCurrent.id);
      };
      timerManager.registerAutoTimeout(
        updateTaskCallback,
        100,
        'updateTaskTimer'
      );
    }
  }
};

export const defineIcon = (option, column) => {
  if (column.name == 'priority') {
    return (
      <img
        src={priorityArray.find((pri) => pri.value === option.value)?.icon}
        width={12}
      />
    );
  }
  return (
    <i
      className={option.icon}
      style={{
        fontSize: 11,
        color: option.color,
        position: 'relative',
        top: -2
      }}
    />
  );
};

/**
 * This function find deep task recursively in activities
 * @param {*} data Activities data
 * @param {*} fieldToFind Field to find deep in data
 * @param {*} value Value to find deep in data
 * @param {*} ret Helper variable to handle retrun
 * @returns Task found or null
 */
export const findDeepGetTask = (data, fieldToFind, value, ret = null) => {
  data.some((e) => {
    if (e[fieldToFind] && e[fieldToFind] == value) {
      /** task found, return true (similar to break) */
      ret = e;
      return true;
    }
    /** find deep on task or children */
    const treeData = e.tasks || e.children;
    if (treeData) {
      ret = findDeepGetTask(treeData, fieldToFind, value, ret);
    }
  });
  return ret;
};

export const getReferenceFromLookahead = (ganttRefTask, activities) => {
  const task = ganttRefTask;
  const activityReferenceLvl = activities.find(
    (ac) => ac.id == task?.activityReference?.proplannerId
  );
  if (!activityReferenceLvl) return;
  const newLookaheadTaskRef = getTask(task.id, null, activityReferenceLvl);
  if (newLookaheadTaskRef?.length) {
    return newLookaheadTaskRef[0];
  }
  return false;
};

export const updateTaskDragSave = (
  taskFromLookahead,
  gantt,
  projectState,
  activityReference,
  parentTask,
  ganttAPI
) => {
  const isRunning =
    gantt.attachToStack &&
    gantt.attachToStack(taskFromLookahead.id, 'updateTaskDragSave');
  if (!isRunning) return;

  const arrToUpdate = [];
  const taskGantt = taskFromLookahead;

  /** calculate End date with new duration in Days */
  getEndDateByGantt(taskGantt, activityReference);

  /** calculate new Duration with calendar */
  const durationToSave = transformDaysToHours(taskGantt.duration);

  /** update references with new duration and end date */
  updateReferences(taskGantt, taskFromLookahead, durationToSave);

  /** Calculates ponderators feature */
  calculatePonderators(
    parentTask || activityReference,
    activityReference,
    (taskCallback) => {
      gantt.updateTask(taskCallback.id);
      addToQueueToSave(arrToUpdate, taskCallback);
    },
    projectState
  );

  try {
    /** refresh view */
    gantt.updateTask(taskFromLookahead.id);

    addToQueueToSave(arrToUpdate, taskFromLookahead, [
      'start_date',
      'end_date',
      'duration'
    ]);

    /** update parent */
    if (taskFromLookahead.parent_id) {
      updateParentDuration(
        taskFromLookahead.parent_id,
        activityReference,
        arrToUpdate,
        gantt,
        ganttAPI,
        projectState
      );
    }

    /** save data */
    updateTaskMassive(arrToUpdate);

    /** Calculates ponderators feature */

    const autoScheduleCallback = () => {
      try {
        gantt.autoSchedule();
      } catch (e) {
        console.log(e);
      }
    };
    timerManager.registerAutoTimeout(
      autoScheduleCallback,
      200,
      'autoScheduleTimer'
    );
  } catch (e) {
    console.log(e);
  }
};

/** Common utils functions */
/**
 * @returns Boolean value that indicates if any grouping filter was applied
 */
export const isGrouped = () =>
  window?.groupBy?.criteria != 'activity' &&
  window?.groupBy?.criteria != 'activityId';

/**
 * This function creates a JSX with the summatory of childs custom ponderators for
 * a task or activity
 * @param {*} parent Parent task or activity to check childs custom ponderators sum
 */
export const checkPonderatorIcon = (
  parent,
  t,
  onlyRead,
  updateAsyncTask = () => {},
  updateAsyncActivity = () => {},
  projectState,
  gantt,
  isToRightOfPonderator = false
) => {
  const { showCustomPonderator, sumPonderators } = getPonderatorSum(parent);

  if (showCustomPonderator) {
    let color = '#34af00';
    if (
      sumPonderators < 99.99545454545456 ||
      sumPonderators >= 100.00545454545457
    ) {
      color = 'red';
    }

    return (
      <span
        className={
          isToRightOfPonderator
            ? 'vertical-center margin-l5 react-dhtmlx'
            : 'react-dhtmlx'
        }>
        {isToRightOfPonderator ? '(' : null}
        <Tooltip title={t('has_manual_ponderator_childs')}>
          <span style={{ paddingRight: 5, color }}>
            {t('lang') === 'en'
              ? parseFloat(sumPonderators).toFixed(
                  Number.isInteger(sumPonderators) ? 0 : 2
                ) + '%'
              : parseFloat(sumPonderators)
                  .toFixed(Number.isInteger(sumPonderators) ? 0 : 2)
                  .replace('.', ',') + '%'}
          </span>
        </Tooltip>
        {onlyRead ? null : (
          <Popconfirm
            onConfirm={() =>
              enableAutoPonderator(
                parent,
                updateAsyncTask,
                updateAsyncActivity,
                projectState,
                gantt
              )
            }
            title={t('are_sure_general')}
            icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}>
            <Tooltip placement="top" title={t('active_ponderator_automatic')}>
              <img
                src={rollbackPonderatorInvertedIcon}
                className="rollback-ponderator-icon"
              />
            </Tooltip>
          </Popconfirm>
        )}
        {isToRightOfPonderator ? ')' : null}
      </span>
    );
  }
  return null;
};

export const getStringFromMoment = (date) => {
  if (typeof date === 'string') {
    return date;
  }
  return moment(date).format('YYYY/MM/DD H:mm');
};

export const recursiveGetStartForParent = (parent, column, gantt) => {
  const originalDate = new Date(getStringFromMoment(parent.start_date));

  let minStart;
  parent.children.map((el, index) => {
    const date = new Date(getStringFromMoment(el.start_date));
    if (!index) {
      minStart = date;
    } else {
      if (date.getTime() < minStart.getTime()) {
        minStart = date;
      }
    }
  });

  parent.start_date = minStart;
  if (originalDate.getTime() != minStart.getTime() && column) {
    updateDataColumn(parent, column, gantt);
  }
};

export const getRecursiveDurationForParent = (parent, column, gantt) => {
  const originalValue = JSON.stringify(parent.sumDuration);
  let total = 0;
  parent.children.map((el) => {
    total += el.duration;
  });
  parent.sumDuration = total;

  if (JSON.stringify(total) != originalValue) {
    updateDataColumn(parent, column, gantt);
  }
};

export const updateDataColumn = (taskCallback, column, gantt) => {
  updateAsyncTaskGanttV2(
    taskCallback,
    column.name,
    taskCallback[column.name],
    gantt
  );
};

// avance traker
export const progress_traker = (projectState) => {
  const loggedUser = getSignedUser();

  totangoEventTracking(
    `p_${projectState.projectSelected}`,
    loggedUser,
    'Progress entry',
    'Timeline View'
  );
};

/**
 * This function defines what parent should has a custom ponderator sum
 * When defining that a parent needs it, this function creates the summatory value
 * @param {*} parent Parent to check custom ponderators sum
 * @returns {object} { boolean: Indicates if parent has custom ponderator active, number: Summatory of custom ponderators }
 */
export const getPonderatorSum = (parent) => {
  let sumPonderators = 0;
  let showCustomPonderator = false;
  const stringToExtract = parent.tasks ? 'tasks' : 'children';
  if (parent.hasCustomPonderator) {
    parent[stringToExtract].map((t) => {
      sumPonderators += t.ponderator;
    });
    showCustomPonderator = true;
  }
  return { showCustomPonderator, sumPonderators };
};

const enableAutoPonderator = (
  parent,
  updateAsyncTask,
  updateAsyncActivity,
  projectState,
  gantt
) => {
  parent.hasCustomPonderator = false;

  /** Calculates ponderators feature */
  const updatePonderatorsData = (taskCallbackup) => {
    updateDataPonderatorInterm(taskCallbackup, gantt);
  };

  calculatePonderators(parent, null, updatePonderatorsData, projectState);

  if (parent.tasks) {
    updateAsyncActivity(parent);
    /** update activity gantt */
    const taskToUpdate = gantt.getTask(Number(parent.unique_id));
    gantt.updateTask(taskToUpdate.id);

    /** update childs */
    updateTaskActPonderator(parent, gantt);
  } else {
    updateAsyncTask(parent);

    /** update childs */
    updateChildsPonderator(parent, gantt);
  }
};

const updateChildsPonderator = (selectedParent, gantt) => {
  if (selectedParent.children) {
    selectedParent.children.forEach((el) => {
      const taskToUpdate = gantt.getTask(el.id);
      gantt.updateTask(taskToUpdate.id);
    });
  }
};

export const collapseExpandActivity = (activity, gantt) => {
  if (activity) {
    activity.$open = !activity.$open;
    if (activity.$open) {
      gantt.open(activity.id);
    } else {
      gantt.close(activity.id);
    }
    gantt.isRenderingOnProcess = false;
    if (gantt) gantt.optimizedRender && gantt.optimizedRender();
  }
};

/**
 * This function adds a new sub task to a parent task
 * @param {*} task Task object, from the parent task to associate it
 * @param {*} activity Activity object, from the activity which must be associated the new task
 * @param {*} projectState State redux for project
 * @param {*} updateAsyncActivity Function to handle socket feature
 * @param {*} updateRender Function to handle refresh view
 */
export const handleChildAdd = async (
  task,
  activity,
  projectState,
  updateAsyncActivity,
  updateRender,
  gantt
) =>
  await createTask(
    task,
    'New sub task',
    activity,
    false,
    projectState,
    updateAsyncActivity,
    updateRender,
    gantt
  );

/**
 * This function adds a new sub task to a last level activity
 * @param {*} activity Activity object, from the activity which must be associated the new task
 * @param {*} projectState State redux for project
 * @param {*} updateAsyncActivity Function to handle socket feature
 * @param {*} updateRender Function to handle refresh view
 */
export const handleAdd = async (
  activity,
  projectState,
  updateAsyncActivity,
  updateRender,
  gantt
) =>
  await createTask(
    null,
    'New task',
    activity,
    false,
    projectState,
    updateAsyncActivity,
    updateRender,
    gantt
  );

export const updateProgressAndPonderator = async ({
  task,
  parentTask,
  activity,
  updateDataPonderator,
  projectState,
  updateDataProgress,
  updateAsyncActivity
}) => {
  calculatePonderators(
    parentTask || activity,
    activity,
    updateDataPonderator,
    projectState
  );
  calculateProgress(
    task,
    parentTask || activity,
    activity,
    updateDataProgress,
    updateAsyncActivity
  );

  if (activity.tasks.length != 0 && !activity.isOnLookahead) {
    const s = await activityService.assignLookahead({
      id: activity.id,
      is_lookahead: true
    });
    activity.isOnLookahead = true;
  }
};

/**
 * This function creates a new task
 * @param {*} parentId ID from the Task that has a lvl up as a parent
 * @param {*} name Name to show to the new task
 * @param {*} activity Activity superior parent object
 */
export const createTask = async (
  parentTask = null,
  name,
  activity,
  copyActivity = false,
  projectState,
  updateAsyncActivity,
  updateRender,
  gantt
) => {
  // trakerServiceAppCues('Creación de tareas en plan intermedio. timeline View')

  const loggedUser = JSON.parse(localStorage.getItem('user'));
  // trakerService(loggedUser.email, projectState.projectSelected, 'Task Creation', 'Lookahead')

  const toAddDynamicAttributes =
    (await getDynamicAttributesToCreateTask()) || {};
  let inheritStartDate = parentTask
    ? parentTask.start_date
    : activity.start_date;
  inheritStartDate = transformDateWithHour(inheritStartDate);
  let toPush = {
    active: false,
    activity_ganttid: activity.correlative_id,
    activityId: activity.id,
    children: [],
    constraints: [],
    cost: 0,
    description: '',
    duration: 1,
    hasCustomPonderator: false,
    hhWorkTime: 0,
    isEditing: true,
    isNew: true,
    lean_status: 'Debit',
    machineryId: null,
    materialId: null,
    name: name,
    parent_id: parentTask ? parentTask.id : parentTask,
    ponderator: 0,
    priority: 'Low',
    progress: 0,
    responsables:
      copyActivity && activity && activity.responsables
        ? activity.responsables
        : [],
    specialtyId: null,
    start_date: inheritStartDate,
    status: 'Waiting',
    subcontractId: copyActivity && activity ? activity.subcontractId : null,
    tags: copyActivity && activity && activity.tags ? activity.tags : [],
    correlative_number: null
  };
  toPush = {
    ...toPush,
    ...toAddDynamicAttributes
  };

  if (copyActivity) {
    toPush.name = activity.name;
    toPush.progress = activity.progress;
    toPush.cost = activity.cost;
    toPush.duration = activity.duration;
    toPush.ponderator = 100;
    toPush.hhWorkTime = activity.hhWorkTime;
  }

  getEndDateByGantt(toPush, activity);

  /** this funtion update parent's */
  const updateDataPonderator = (taskCallback) => {
    updateAsyncTaskGanttV2(
      taskCallback,
      'ponderator',
      taskCallback.ponderator,
      gantt
    );
  };

  const updateDataProgress = (taskCallback) => {
    updateAsyncTaskGanttV2(
      taskCallback,
      'progress',
      taskCallback.progress,
      gantt
    );
  };

  const res = await taskService.create(toPush);
  if (res) {
    toPush.id = res.id;
    if (parentTask) {
      parentTask.children.push(toPush);
    } else {
      activity.tasks.push(toPush);
    }

    const autoScheduleCallback = async () => {
      calculatePonderators(
        parentTask || activity,
        activity,
        updateDataPonderator,
        projectState
      );
      calculateProgress(
        toPush,
        parentTask || activity,
        activity,
        updateDataProgress,
        updateAsyncActivity
      );

      if (activity.tasks.length != 0 && !activity.isOnLookahead) {
        const s = await activityService.assignLookahead({
          id: activity.id,
          is_lookahead: true
        });
        activity.isOnLookahead = true;
      }

      if (activity.tasks.length > 0) {
        assignCorrelativeNumbers(activity.tasks);
      }

      updateRender();
      window.ganttVisualization &&
        window.ganttVisualization.render &&
        window.ganttVisualization.render();
      clearSelectedTasks();
    };
    timerManager.registerAutoTimeout(
      autoScheduleCallback,
      500,
      'autoScheduleTimer'
    );
    return toPush;
  }

  const renderCallback = () => {
    if (!window.ganttVisualization) return;
    const gantt = window.ganttVisualization;
    if (!gantt.$keyboardNavigation) return;
    if (!gantt.$keyboardNavigation.dispatcher) return;
    gantt.$keyboardNavigation.dispatcher.activeNode = null;
    gantt.$keyboardNavigation.dispatcher.isActive = false;
    gantt.render && gantt.render();
  };
  timerManager.registerAutoTimeout(renderCallback, 200, 'renderTimer');
};

export const assignCorrelativeNumbers = (tasks) => {
  let counter = 1;
  const usedNumbers = new Set();
  const saveTasksMassive = [];

  function assignRecursive(task) {
    while (usedNumbers.has(counter)) {
      counter++;
    }
    task.correlative_number = counter;

    saveTasksMassive.push({
      id: task.id,
      correlative_number: counter
    });

    if (!window.ganttVisualization) return;

    const currentTaskGantt = window.ganttVisualization.getTask(task.id);
    if (currentTaskGantt) currentTaskGantt.correlative_number = counter;

    usedNumbers.add(counter);
    if (task.children && task.children.length > 0) {
      for (const child of task.children) {
        assignRecursive(child);
      }
    }
  }
  for (const task of tasks) {
    assignRecursive(task);
  }

  updateTaskMassive(saveTasksMassive);
};

export const assignCorrelativeNumbersLookahead = (
  lookaheadTasks,
  currentTask
) => {
  for (const task of lookaheadTasks) {
    if (task.id === currentTask.id) {
      task.correlative_number = currentTask.correlative_number;
      return;
    }

    if (task.children && task.children.length > 0) {
      assignCorrelativeNumbersLookahead(task.children, currentTask);
    }
  }
};

export const sortByCorrelativeNumber = (tasks) => {
  tasks.sort((a, b) => a.correlative_number - b.correlative_number);

  for (const task of tasks) {
    if (task.children && task.children.length > 0) {
      sortByCorrelativeNumber(task.children);
    }
  }
};

export const updateAsyncParentRecursive = (gantt, idTask) => {
  if (!idTask) return;

  const parentAct = gantt.getTask(idTask);
  const getReferenceTask = findDeepGetTask(window.activities, 'id', idTask);
  if (!getReferenceTask) return;

  getProgressForParent(getReferenceTask);

  if (gantt.isTaskExists(parentAct.id)) {
    parentAct.progress = getReferenceTask.progress;
    gantt.updateTask(parentAct.id);
  }

  if (getReferenceTask.parent_id) {
    updateAsyncParentRecursive(getReferenceTask.parent_id);
  }
};

const getProgressForParent = (parent) => {
  const childs = parent.tasks || parent.children;
  let progress = 0;
  childs.map((toExtractFrom) => {
    progress += (toExtractFrom.ponderator / 100) * toExtractFrom.progress;
  });
  parent.progress = progress;
};

export const clearSelectedTasks = () => {
  if (window.ganttVisualization === undefined) return;
  if (window.ganttVisualization.unselectTask === undefined) return;
  let doesExistOtherLevel = true;
  while (doesExistOtherLevel) {
    if (!window.ganttVisualization.unselectTask()) {
      doesExistOtherLevel = false;
    }
  }
};

/**
 * On click function when pressing delete
 * @param {*} task Top lvl task to start tree deleting
 * @param {*} activity Activity parent object
 * @param {*} parent If task is from another than first level must be specified the parent task to attack it children array
 */
export const handleDelete = async (
  task,
  activity,
  parent = null,
  updateAsyncTask,
  updateAsyncActivity,
  projectState,
  t,
  updateRender,
  gantt
) => {
  const tasksToDelete = [];
  const isLastChildren = parent?.children.length === 1 && parent !== null;
  getTasksToDelete(task, tasksToDelete);
  const asyncMap = tasksToDelete.map(async (toDelete) => {
    if (gantt.isTaskExists(toDelete.id)) {
      gantt.deleteTask(toDelete.id);
    }
    await deleteTaskHandler(
      toDelete,
      activity,
      parent,
      updateAsyncTask,
      updateAsyncActivity,
      projectState,
      isLastChildren
    );
  });

  await Promise.all(asyncMap);
  const loggedUser = getSignedUser();

  totangoEventTracking(
    `p_${projectState.projectSelected}`,
    loggedUser,
    'Task deleted',
    'Lookahead'
  );

  notifyMessage({
    title: t('deleted_succesfully_title'),
    message: t('deleted_succesfully'),
    type: 'success'
  });

  const renderCallback = () => {
    updateRender();
  };
  timerManager.registerAutoTimeout(renderCallback, 300, 'renderTimer');
};

/**
 * This functions shows a pretty alert to user
 * @param {*} data Object { title, message, type }
 */
const notifyMessage = (data) => {
  const alertErrorMailExists = {
    title: data.title,
    description: data.message,
    type: data.type
  };
  openNotification(alertErrorMailExists);
};

export const isRestrictedTask = (task) => {
  const released = task.constraints.filter(
    (constraint) => constraint.status == 'released'
  );
  const restricted =
    task.constraints.length && task.constraints.length != released.length;
  return restricted;
};

/**
 * This function recursively evaluates if the task is restricted.
 * @param {*} task This reference to current Task
 * @param {*} parentTask Parent task if it is not from first level
 * @param {*} updateAsyncTask Function to handle socket feature
 * @returns status
 */
export const defineRestrictedTaskByChilds = (
  task,
  parentTask,
  updateAsyncTask = () => {}
) => {
  const released = task.constraints.filter(
    (constraint) => constraint.status == 'released'
  );
  const restricted =
    task.constraints.length && task.constraints.length != released.length;
  const copyOfLeanStatus = JSON.parse(JSON.stringify(task.lean_status));

  if (restricted && task.lean_status != 'Restricted') {
    task.status_before_restricted = task.lean_status;
    task.lean_status = 'Restricted';
  } else if (!restricted && task.lean_status == 'Restricted') {
    task.lean_status = 'Can';
  }

  if (parentTask && !restricted) {
    /** parent is restricted */
    const releasedParent = parentTask.constraints.filter(
      (constraint) => constraint.status == 'released'
    );
    const restrictedParent =
      parentTask.constraints.length &&
      parentTask.constraints.length != releasedParent.length;
    if (!restrictedParent) {
      const allChildrensAreRestricted = parentTask.children.every(
        (el) => el.lean_status === 'Restricted'
      );
      const copyOfLeanStatusParent = JSON.parse(
        JSON.stringify(parentTask.lean_status)
      );
      if (allChildrensAreRestricted) {
        parentTask.status_before_restricted = parentTask.lean_status;
        parentTask.lean_status = 'Restricted';
        parentTask.is_restricted = true;
        parentTask.restricted = true;
        if (copyOfLeanStatusParent != parentTask.lean_status) {
          updateAsyncTask(parentTask);
        }
      } else {
        if (parentTask.lean_status === 'Restricted') {
          parentTask.is_restricted = false;
          parentTask.restricted = false;
          if (parentTask.status_before_restricted == 'Debit') {
            parentTask.lean_status = 'Debit';
          } else {
            parentTask.lean_status = 'Can';
            const someChildrensAreWill = parentTask.children.some(
              (el) => el.lean_status === 'Will'
            );
            if (parentTask?.status_before_restricted === 'Restricted') {
              parentTask.lean_status = someChildrensAreWill ? 'Will' : 'Debit';
            }
          }
          if (copyOfLeanStatusParent != parentTask.lean_status) {
            updateAsyncTask(parentTask);
          }
        } else {
          /** if the childs have any restriction, do it whould, unless it is weekly plan */
          const someChildrensAreRestricted = parentTask.children.some(
            (el) => el.lean_status === 'Restricted'
          );
          parentTask.is_restricted = false;
          parentTask.restricted = false;
          if (someChildrensAreRestricted && parentTask.lean_status !== 'Will') {
            parentTask.lean_status = 'Debit';
            if (copyOfLeanStatusParent != parentTask.lean_status) {
              updateAsyncTask(parentTask);
            }
          } else {
            /** check if all child tasks have the same lean status */
            let firstLeanStatus;
            if (parentTask.children[0]) {
              firstLeanStatus = parentTask.children[0].lean_status;
            }
            /** check if some child is Will */
            const checkChildsSomeLeanStatus = parentTask.children.some(
              (el) => el.lean_status === 'Will'
            );
            if (checkChildsSomeLeanStatus) {
              parentTask.lean_status = 'Will';
            } else {
              const checkChildsAllLeanStatus = parentTask.children.every(
                (el) => el.lean_status === firstLeanStatus
              );
              if (checkChildsAllLeanStatus) {
                parentTask.lean_status = firstLeanStatus;
              } else {
                if (parentTask.lean_status !== 'Will') {
                  parentTask.lean_status = 'Debit';
                }
              }
            }
            if (copyOfLeanStatusParent != parentTask.lean_status) {
              updateAsyncTask(parentTask);
            }
          }
        }
      }
    }
  }
  const leanStatusChangeCallback = () => {
    trakerServiceAppCues('Lean Status Change');
  };
  timerManager.registerAutoTimeout(
    leanStatusChangeCallback,
    5000,
    'leanStatusChangeTimer'
  );
  if (copyOfLeanStatus != task.lean_status) {
    updateAsyncTask(task);
  }
  return restricted;
};

export const renderModalCustomPonderator = (
  showModal,
  setShowModal,
  selectedParent,
  setSelectedParent,
  updateAsyncActivity = () => {},
  updateAsyncTask = () => {},
  gantt,
  t
) => (
  /* Modal for creating custom ponderator */
  <Modal
    title={t('carefull_modal_title')}
    visible={showModal}
    onCancel={() => setShowModal(false)}
    footer={[
      <Button
        key="submit"
        type="primary"
        onClick={() => {
          disableAutoPonderator(
            selectedParent,
            setShowModal,
            updateAsyncActivity,
            updateAsyncTask,
            gantt
          );
        }}>
        {t('got_it_disable_auto_ponderator')}
      </Button>
    ]}>
    <p>
      {t('with_this_action_disable')}
      <b>{t('bold_ponderator_manual_label')}</b>
      {t('rest_of_disable_message')}
    </p>
  </Modal>
);

// Modal for dealing with activity modification request
export const renderModalRequestModification = (
  modalModification,
  setModalModification,
  t,
  dispatch,
  projectState,
  userActions
) => (
  <ModificationRequestsModal
    visible={modalModification.visible}
    modalModification={modalModification}
    taskId={modalModification.data.task.id}
    onCancel={() =>
      setModalModification({
        ...modalModification,
        visible: false,
        description: ''
      })
    }
    onUpdateComment={(description) =>
      setModalModification({ ...modalModification, description })
    }
    onSend={() =>
      createActivityModificationRequest(
        modalModification.data.task,
        modalModification.data.activity,
        t,
        modalModification,
        setModalModification,
        activityModificationService,
        dispatch,
        projectState,
        userActions
      )
    }
    t={t}
  />
);

/**
 * This function enable custom ponderator for an activity or task selected at component state
 */
const disableAutoPonderator = (
  selectedParent,
  setShowModal,
  updateAsyncActivity,
  updateAsyncTask,
  gantt
) => {
  selectedParent.hasCustomPonderator = true;
  if (selectedParent.tasks) {
    updateAsyncActivity(selectedParent);

    /** update activity gantt */
    const taskToUpdate = gantt.getTask(Number(selectedParent.unique_id));
    gantt.updateTask(taskToUpdate.id);

    /** update childs */
    updateTaskActPonderator(selectedParent, gantt);
  } else {
    updateAsyncTask(selectedParent);
    /** update task gantt */
    const taskToUpdate = gantt.getTask(selectedParent.id);
    gantt.updateTask(taskToUpdate.id);

    /** update childs */
    updateChildsPonderator(selectedParent, gantt);
  }
  setShowModal(false);
};

/** this funtion update parent's */
const updateDataPonderatorInterm = (taskCallback, gantt) => {
  updateDataColumn(taskCallback, { name: 'ponderator' }, gantt);
};

const updateTaskActPonderator = (selectedParent, gantt) => {
  if (selectedParent.tasks) {
    selectedParent.tasks.forEach((el) => {
      const taskToUpdate = gantt.getTask(el.id);
      gantt.updateTask(taskToUpdate.id);
    });
  }
};

export const massiveUnselected = (gantt, setMassiveSelection) => {
  gantt.batchUpdate(() => {
    gantt.eachSelectedTask((task_id) => {
      if (gantt.isTaskExists(task_id)) {
        gantt.update_checkbox(task_id, false);
      }
    });
  });
  setMassiveSelection([]);
};

export const getColorByStatus = (task) => {
  let color;
  switch (task.status) {
    case 'Advancement':
      /** blue */
      color = '#415ad9';
      // color = '#155D77'
      break;
    case 'Overdue':
      /** red */
      color = '#E50101';
      break;
    case 'Waiting':
      /** grey */
      color = '#121212';
      break;
    case 'Doing':
      /** orange */
      color = '#F59D04';
      break;
    case 'Done':
      /** green */
      color = '#34AF00';
      break;
  }
  return color;
};

export const defaultGanttFields = [
  'checked',
  'name',
  'actions',
  'roadblocks',
  'start_date',
  'duration',
  'end_date',
  'progress',
  'responsables',
  'subcontractId',
  'plan_endowment'
];

export const updateAsyncActivityGantt =
  (gantt, task, updateAsyncActivity, updateActivityParent, sectorId) =>
  (actCallback) => {
    /** check status according progress */
    if (actCallback.progress == 0) {
      actCallback.status = 'Waiting';
    } else if (actCallback.progress > 0 && actCallback.progress < 100) {
      actCallback.status = 'Doing';
    } else {
      actCallback.status = 'Done';
    }

    /** update backend data */
    updateAsyncActivity(actCallback);

    const objectFromGantt = gantt.getTask(actCallback.unique_id);
    if (objectFromGantt) {
      objectFromGantt.progress = actCallback.progress;
      objectFromGantt.progress_fix = actCallback.progress;
      gantt.updateTask(objectFromGantt.id);
    }

    if (gantt.fixActs) {
      const indexItem = gantt.fixActs.findIndex(
        (elem) => elem.idActivity === actCallback.unique_id
      );
      if (indexItem != -1) {
        gantt.fixActs[indexItem].progress_fix = actCallback.progress;
      } else {
        gantt.fixActs &&
          gantt.fixActs.push({
            idActivity: actCallback.unique_id,
            progress_fix: actCallback.progress
          });
      }
    }

    /** update progress for callback act of the gantt */
    const currentAct = gantt.getTask(task.parent);

    if (currentAct) {
      currentAct.progress = actCallback.progress;

      /** check status according progress */
      if (currentAct.progress == 0) {
        currentAct.status = 'Waiting';
      } else if (currentAct.progress > 0 && currentAct.progress < 100) {
        currentAct.status = 'Doing';
      } else {
        currentAct.status = 'Done';
      }

      /** update activity parent */
      if (currentAct.parent !== '0') {
        updateActivityParent(currentAct, gantt, sectorId, actCallback);
      }
      gantt.updateTask(currentAct.id);
    }
  };

/**
 * this function update taks seleted in gantt
 * @param {*} gantt reference to gantt object
 */
export const updateTasksSelected = (gantt) => {
  gantt.batchUpdate(() => {
    gantt &&
      gantt.eachSelectedTask &&
      gantt.eachSelectedTask((task_id) => {
        if (gantt.isTaskExists(task_id)) {
          gantt.updateTask(task_id);
        }
      });
  });
};

/**
 * This function check if current task is parent
 * @param {*} task current task
 * @param {*} parentTask current parent task
 * @param {*} gantt object reference to gantt api
 */
export const updateIsParent = (task, parentTask, gantt) => {
  const adaptedTask = convertPPTask2PluginTask(parentTask, null, task);
  const currentRefToTaskInGantt = gantt.getTask(adaptedTask.id);
  currentRefToTaskInGantt.is_parent = Boolean(adaptedTask.is_parent);
  currentRefToTaskInGantt.isParentTask = Boolean(adaptedTask.isParentTask);
  gantt.updateTask(currentRefToTaskInGantt.id);
};

/**
 * This function updates the parent activity up
 * @param {*} act activity to update
 * @param {*} gantt object gantt reference
 * @param {*} sectorId current sector
 * @param {*} excludeFirstAct activity to exclude
 */
export const updateActivityParent = async (
  act,
  gantt,
  sectorId,
  excludeFirstAct
) => {
  /** update first level activity */
  if (act.id == excludeFirstAct.unique_id) {
    act.progress = excludeFirstAct.progress;
    gantt.updateTask(act.id);
  }

  if (act.parent !== '0') {
    const parentAct = gantt.getTask(act.parent);
    if (parentAct) {
      /** get recursive progress */
      await getRecursiveFromParentActivity(
        parentAct,
        'progress',
        act,
        sectorId
      );
      if (parentAct.parent) {
        /** calculate act.progress */
        await updateActivityParent(parentAct, gantt, sectorId, excludeFirstAct);
      }
    }
  }
};

/**
 * This function calculate the progress for an activity
 * @param {*} act activity to calculate
 * @param {*} attributeToIterate name of activity's field
 * @param {*} excludeAct activity to exclude
 * @param {*} sectorId current sector
 */
const getRecursiveFromParentActivity = async (
  act,
  attributeToIterate,
  excludeAct,
  sectorId
) => {
  /** get all child activities */
  const getChilds = await activityService.showChilds({
    sector_id: sectorId,
    unique_id: act.id
  });
  let total = 0;
  getChilds.childs.map((el) => {
    if (el.ponderator) {
      /** get progress from current activity (this was updated) */
      if (el.unique_id == excludeAct.id) {
        total += excludeAct.progress * (el.ponderator / 100);
      } else {
        total += el.progress * (el.ponderator / 100);
      }
    }
  });
  act[attributeToIterate] = total;
};

/**
 * This function allows us to calculate the end date for a parent with children
 * @param {*} parent Parent task to which we will calculate the end date
 */
export const getEndForParent = (parent) => {
  let maxEnd;
  parent.children.map((el, index) => {
    const date = new Date(getStringFromMoment(el.end_date));
    if (!index) {
      maxEnd = date;
    } else {
      if (date.getTime() > maxEnd.getTime()) {
        maxEnd = date;
      }
    }
  });
  parent.end_date = maxEnd;
};

/**
 * This function allows us to enqueue an array of tasks to later save them
 * Object: {
                id: task.id,
                ponderator: task.ponderator,
                duration: task.duration,
                end_date: task.end_date
            }
 * @param {*} arrToUpdate queue of tasks that we want to save
 * @param {*} task task we want to add to the queue
 * @param {*} fields fields we want to save
 */
export const addToQueueToSave = (
  arrToUpdate,
  task,
  fields = ['ponderator', 'duration', 'end_date']
) => {
  const objToPush = {
    id: task.id
  };

  fields.forEach((field) => {
    if (['end_date', 'start_date'].includes(field)) {
      const offsetTZ = new Date().getTimezoneOffset() / -60;
      const variable =
        offsetTZ < 0
          ? moment(task[field], 'YYYY-MM-DD HH:mm').utc(offsetTZ)
          : moment(task[field], 'YYYY-MM-DD HH:mm');
      objToPush[field] = variable;
    } else {
      objToPush[field] = task[field];
    }
  });

  arrToUpdate.push(objToPush);
};

/**
 * This function allows us to validate values not allowed in the duration
 * @param {*} taskGantt task we want to validate
 * @param {*} t translation variable
 */
export const validateMinMax = (taskGantt, t) => {
  /** validations */
  if (parseFloat(taskGantt.durationDays) > 200) {
    openNotification({
      title: t('master_plan.duration'),
      description: t('lookahead.duration_max_alert'),
      type: 'warning',
      key: 'duration'
    });
    taskGantt.durationDays = 200;
  }
  if (parseFloat(taskGantt.durationDays) <= 0) {
    openNotification({
      title: t('master_plan.duration'),
      description: t('lookahead.duration_min_alert'),
      type: 'warning',
      key: 'duration'
    });
    taskGantt.durationDays = transformHourToDays(1);
  }
};

/**
 * This function gives us the reference to the activity, the parent and the task itself.
 * @param {*} taskGantt gantt task
 * @returns an object with the variables: { activityReference, taskFromLookahead, parentTask }
 */
export const getReferences = (taskGantt) => {
  /** get activity reference */
  const findAct = findDeepGetActivityOfTask(
    window.activities,
    'id',
    taskGantt.id
  );
  let activityReference;
  if (findAct) activityReference = findAct;

  /** get reference task (taskFromLookahead) from activities */
  const taskFromLookahead = findDeepGetTask(
    window.activities,
    'id',
    taskGantt.id
  );

  /** get parent task */
  let parentTask = null;
  let disabled = true;
  if (taskFromLookahead) {
    disabled = taskFromLookahead.children.length > 0;
    if (taskFromLookahead.parent_id) {
      const doesExistAtReferenceParent = findDeepGetTask(
        window.activities,
        'id',
        taskFromLookahead.parent_id
      );
      parentTask = doesExistAtReferenceParent || null;
    }
  }

  return { activityReference, taskFromLookahead, parentTask };
};

/**
 * This function allows us to update the duration in the reference to the task and also in the gantt task
 * @param {*} taskGantt gantt task
 * @param {*} taskFromLookahead reference to task
 * @param {*} durationToSave new duration
 */
export const updateReferences = (
  taskGantt,
  taskFromLookahead,
  durationToSave
) => {
  /** update task gantt */
  taskGantt.duration = durationToSave;
  taskGantt.for_disable_milestone_duration = durationToSave;
  taskGantt.duration_milestone_bugged = durationToSave;
  taskGantt.for_disable_milestone_duration = durationToSave;
  taskGantt.non_parsed_original_duration = durationToSave;
  taskGantt.durationDays = transformHourToDays(durationToSave);

  /** update task reference */
  taskFromLookahead.duration = transformHourToDays(durationToSave);
  taskFromLookahead.end_date = taskGantt.end_date;
};

/**
 * This function calculates the duration of the parent, as well as adds it to the queue to be saved
 * @param {*} idTask id task parent
 * @param {*} activityReference reference activity
 * @param {*} arrToUpdate array to add the tasks to be saved
 * @param {*} gantt object gantt
 * @param {*} ganttAPI gantt api object
 */
export const updateParentDuration = (
  idTask,
  activityReference,
  arrToUpdate,
  gantt,
  ganttAPI,
  projectState = false,
  isParentFalse = null
) => {
  const getReferenceTask = findDeepGetTask(window.activities, 'id', idTask);
  const taskRef = getReferenceTask || null;
  const taskGantt = gantt.getTask(idTask);

  getEndForParent(taskRef);
  if (taskRef.children.length) {
    recursiveGetStartForParent(taskRef, 'start_date', gantt);
  }

  const durationToSave = ganttAPI.calculateDuration(
    taskRef.start_date,
    taskRef.end_date,
    taskGantt.calendar_id
  );
  taskRef.duration = parseFloat(transformHourToDays(durationToSave));

  /** update task gantt */
  taskGantt.duration = durationToSave;
  taskGantt.end_date = taskRef.end_date;
  taskGantt.start_date = taskRef.start_date;

  /** refresh view */
  gantt.updateTask(taskGantt.id);

  addToQueueToSave(arrToUpdate, taskRef);
  /** Calculates ponderators feature */
  if (projectState) {
    /** get activity, task, and parent references */
    const { activityReference, taskFromLookahead, parentTask } =
      getReferences(taskGantt);
    calculatePonderators(
      parentTask || activityReference,
      activityReference,
      (taskCallback) => {
        gantt.updateTask(taskCallback.id);
        addToQueueToSave(arrToUpdate, taskCallback);
      },
      projectState
    );
  }
  if (taskRef.parent_id) {
    updateParentDuration(
      taskRef.parent_id,
      activityReference,
      arrToUpdate,
      gantt,
      ganttAPI,
      projectState
    );
  }
};

/**
 * This function allows us to massively update an array of objects mapped to the task structure.
 * @param {*} tasks Array of objects with the task data that we want to update
 */
export const updateTaskMassive = async (arrTasks) => {
  workerService.callAction(save_massive_task_lookahead, arrTasks, () => {
    taskService.updateMassive(arrTasks);
  });
};

/**
 * This fuction return a clean string without special characters
 * @param {*} str string to clean
 * @returns string clean
 */

export const sanitizeString = (str) => str.replace(/<|>/g, '');

export const disabledDragDrop = (t, type = 'activities') => {
  notifyMessage({
    title:
      type === 'order'
        ? t('lookahead.title_drag_drop_tasks_activites_order')
        : type === 'childrens'
          ? t('lookahead.title_drag_drop_tasks_activites_childrens')
          : t('lookahead.title_drag_drop_tasks_activites'),
    message:
      type === 'order'
        ? t('lookahead.description_drag_drop_tasks_activites_order')
        : type === 'childrens'
          ? t('lookahead.description_drag_drop_tasks_activites_childrens')
          : t('lookahead.description_drag_drop_tasks_activites'),
    type: 'error'
  });
};

export const checkIfTaskHasChildren = (taskId) => {
  if (!taskId) return true;

  const task = findDeepGetTask(window.activities, 'id', taskId);

  if (!task?.children) return true;

  return Boolean(task.children.length);
};

export const evaluateCasoOfAllChildrenDeleted = (
  massiveSelection,
  windowActivities
) =>
  massiveSelection
    .reduce((acc, curr) => {
      const parentId = curr.parent_id;
      const existingEntry = acc.find((entry) => entry.parent_id === parentId);

      if (existingEntry) {
        existingEntry.num_tasks_del++;
      } else {
        const findParentTask = findDeepGetTask(
          windowActivities,
          'id',
          parentId
        );
        if (parentId) {
          const num_tasks = findParentTask?.children?.length || 0;
          acc.push({
            parent_id: parentId,
            num_tasks_del: 1,
            num_tasks: num_tasks
          });
        }
      }
      return acc;
    }, [])
    .filter((entry) => entry.num_tasks_del === entry.num_tasks);
