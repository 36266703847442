import { updateAsyncTaskGanttV2 } from '../../GanttVisualization.helper';
import { renderPriorityToSelect, renderSelectedOption } from './template';

export const prepareUIElements = (options) => {
  const { selectedObject, optionsList, isOnlyReadElement, column, task } =
    options;

  const selectedPriority = selectedObject.priority
    ? optionsList.find(
        (priorities) => priorities.value === selectedObject.priority
      )
    : null;

  const placeholderHtml = selectedPriority
    ? renderSelectedOption(selectedPriority, isOnlyReadElement, task)
    : null;

  const optionsListHtml = optionsList
    .map(
      (option) => `
      <li class="dropdown-option items-list" style="cursor: pointer; list-style: none;"
          data-action="prioritySelection" data-value="${option.value}">
        ${renderPriorityToSelect({ object: option }, column, selectedPriority)}
      </li>
    `
    )
    .join('');

  return { placeholderHtml, optionsListHtml };
};

export const registerTaskCallbacks = (taskId, task, options) => {
  const { taskFromLookahead, column, gantt, callbackOnSelection } = options;

  window.priorityCallbacks = window.priorityCallbacks || {};
  window.priorityCallbacks[taskId] = {
    selection: (id) => {
      callbackOnSelection && callbackOnSelection(id);
      if (taskFromLookahead) {
        updateAsyncTaskGanttV2(taskFromLookahead, column.name, id, gantt);
      }
    }
  };
};

const handlePriorityAction = (action, target, taskId) => {
  const callbacks =
    window.priorityCallbacks && window.priorityCallbacks[taskId];
  if (!callbacks) return;
  const actionHandlers = {
    prioritySelection: () => {
      const value = target.getAttribute('data-value');
      callbacks.selection && callbacks.selection(value);
    }
  };

  const handler = actionHandlers[action];
  if (handler) {
    handler();
  }
};

export const setupGlobalHandlers = () => {
  window.priorityCallbacks = window.priorityCallbacks || {};
  if (window._priorityGlobalHandler) return;
  const priorityGlobalHandler = (event) => {
    const target = event.target.closest('[data-action]');
    if (!target) return;
    const action = target.getAttribute('data-action');
    const container = target.closest('[data-task-id]');
    if (!container) return;
    const taskId = container.getAttribute('data-task-id');
    handlePriorityAction(action, target, taskId);
    event.stopPropagation();
  };
  document.addEventListener('click', priorityGlobalHandler);
  window._priorityGlobalHandler = priorityGlobalHandler;
};

export const cleanupPriorityGlobalHandlers = () => {
  if (window._priorityGlobalHandler) {
    document.removeEventListener('click', window._priorityGlobalHandler);
    delete window._priorityGlobalHandler;
  }
};
